import React from "react"
import { Product, useProducts } from "./ProductLoaderStore"
import fuzzysort from "fuzzysort"

interface State {
  productLineFilter: string[]
  searchString: string
  isSearching: boolean
  searchedProducts: Fuzzysort.KeysResults<Product>

  onSearch: (searchString: string, done: boolean) => void
  onFilterProductLine: (productLineId: string, checked: boolean) => void
  onResetFilters: () => void
}

const initialState: State = {
  productLineFilter: [],
  searchString: "",
  isSearching: false,
  searchedProducts: Object.assign([], { total: 0 }),

  onSearch: () => {
    throw new Error("TableViewProvider missing")
  },
  onFilterProductLine: () => {
    throw new Error("TableViewProvider missing")
  },
  onResetFilters: () => {
    throw new Error("TableViewProvider missing")
  },
}

const Context = React.createContext(initialState)
export const useSearchFilter = () => React.useContext(Context)

export const SearchFilterProvider = ({ children }: React.PropsWithChildren) => {
  const [searchString, setSearchString] = React.useState(
    initialState.searchString
  )
  const [isSearching, setIsSearching] = React.useState(initialState.isSearching)

  const [productLineFilter, setProductLineFilter] = React.useState(
    initialState.productLineFilter
  )

  const productsRes = useProducts()

  const onFilterProductLine: State["onFilterProductLine"] = React.useCallback(
    (productLineId, checked) => {
      if (!checked) {
        setProductLineFilter((p) => p.filter((line) => line !== productLineId))
      } else {
        setProductLineFilter((p) => [...p, productLineId])
      }
    },
    []
  )

  const onSearch: State["onSearch"] = React.useCallback(
    (searchString, done) => {
      setIsSearching(!done)
      setSearchString(searchString)
    },
    []
  )

  const onResetFilters = React.useCallback(() => {
    setProductLineFilter([])
  }, [])

  const searchedProducts = React.useMemo(() => {
    const products = productsRes.status === "loaded" ? productsRes.data : []

    return fuzzysort.go(
      isSearching ? "" : searchString,
      productLineFilter.length > 0
        ? products.filter((p) => productLineFilter.includes(p.line.id))
        : products,
      {
        keys: [`product.name`, `sku`, `line.name`, `id`],
        all: true,
      }
    )
  }, [isSearching, productLineFilter, productsRes, searchString])

  return (
    <Context.Provider
      value={{
        searchedProducts,
        searchString,
        productLineFilter,
        isSearching,
        onFilterProductLine,
        onResetFilters,
        onSearch,
      }}
    >
      {children}
    </Context.Provider>
  )
}
