import styled from "@emotion/styled"
import { Stack } from "../ui/Stack"
import { BoldText } from "../ui/Typography"
import { Checkbox } from "../ui/Checkbox"
import { PopoverClose } from "@radix-ui/react-popover"
import { Product } from "../../store/ProductLoaderStore"
import React from "react"
import { useSearchFilter } from "../../store/SearchFilterStore"
import { CTAButton } from "../ui/CTAButton"

const Wrapper = styled(Stack)`
  background-color: ${(p) => p.theme.colors.neutral00};
  padding: 16px;

  box-shadow: 0px 16px 32px 0px rgba(28, 30, 45, 0.2);
  border-radius: 8px;

  height: 100%;
  overflow: auto;
`

const ResetButton = styled(CTAButton)`
  color: ${(p) => p.theme.colors.red06};

  &:hover {
    color: ${(p) => p.theme.colors.red06};
  }

  &:disabled {
    color: ${(p) => p.theme.colors.red03};
  }
`

export const FilterPopover = ({ products }: { products: Product[] }) => {
  const { productLineFilter, onFilterProductLine, onResetFilters } =
    useSearchFilter()

  const availableProductLines = React.useMemo(() => {
    const productLines = new Map(products.map((p) => [p.line.id, p.line.name]))
    return productLines
  }, [products])

  return (
    <Wrapper d="v" dist="md">
      <BoldText textColor="text1">Product line</BoldText>
      <Stack d="v" dist="sm">
        {[...availableProductLines.entries()].map(([id, lineName]) => (
          <Checkbox
            key={id}
            label={lineName}
            checked={productLineFilter.includes(id)}
            onCheckedChange={(checked) => {
              onFilterProductLine(id, checked === true)
            }}
          />
        ))}
      </Stack>
      <PopoverClose asChild>
        <ResetButton
          disabled={productLineFilter.length === 0}
          onClick={() => {
            onResetFilters()
          }}
        >
          Reset
        </ResetButton>
      </PopoverClose>
    </Wrapper>
  )
}
