import { useParams } from "react-router-dom"
import { DetailsHeader } from "../components/containers/DetailsHeader"
import { MainLayout } from "../components/containers/mainLayout"
import { useProducts } from "../store/ProductLoaderStore"
import { DetailsContent } from "../components/containers/DetailsContent"
import { BodyText } from "../components/ui/Typography"
import { Stack } from "../components/ui/Stack"

export const Details = () => {
  const { id } = useParams<{ id: string }>()
  const productRes = useProducts()

  if (!id) {
    return <div>Invalid ID</div>
  }

  if (productRes.status === "failed") {
    return <div>Failed loading products</div>
  }

  if (productRes.status === "loading") {
    return <div>Loading...</div>
  }

  const product = productRes.data.find((p) => p.id === id)

  if (!product) {
    return <div>Product not found</div>
  }

  return (
    <MainLayout>
      <Stack d="v" style={{ padding: "0px 32px" }} as="main">
        <DetailsHeader products={productRes.data} productId={product?.id} />
        {product ? (
          <DetailsContent product={product} />
        ) : (
          <BodyText>Product not found</BodyText>
        )}
      </Stack>
    </MainLayout>
  )
}
