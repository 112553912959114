import styled from "@emotion/styled"
import { Logo } from "../icons/Logo"
import { BodyText } from "../ui/Typography"
import { Stack } from "../ui/Stack"
import { Link } from "react-router-dom"

export const NAVIGATION_BAR_HEIGHT = 50

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${NAVIGATION_BAR_HEIGHT}px;
  min-height: ${NAVIGATION_BAR_HEIGHT}px;
  padding-left: 5px;
  padding-right: 32px;

  background-color: ${(p) => p.theme.colors.neutral02};
`

const LogoWrapper = styled(Stack)`
  color: ${(p) => p.theme.colors.neutral10};
`

const LogoLink = styled(Link)`
  all: unset;
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.colors.ublue08};
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.ublue08};
  }
`

export const NavigationBar = () => {
  return (
    <Wrapper>
      <LogoWrapper dist="md" style={{ alignItems: "center" }}>
        <LogoLink to="/" aria-label="Go home">
          <Logo />
        </LogoLink>
        <BodyText>Devices</BodyText>
      </LogoWrapper>
      <BodyText>stoltzrobin</BodyText>
    </Wrapper>
  )
}
