import { Theme } from "@emotion/react"
import styled from "@emotion/styled"

interface TypographyProps extends React.HTMLAttributes<HTMLSpanElement> {
  textColor?: Exclude<keyof Theme["colors"], "text3">
  theme?: Theme
}

const getTextColorWithDefault = (props: TypographyProps) => {
  return props.textColor
    ? props.theme?.colors[props.textColor]
    : props.theme?.colors.text3
}

export const SubTitle = styled.span<TypographyProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${getTextColorWithDefault};
`

export const BodyText = styled.span<TypographyProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${getTextColorWithDefault};
`

export const Label = styled.label<TypographyProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${getTextColorWithDefault};
`

export const BoldText = styled.span<TypographyProps>`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${getTextColorWithDefault};
`

export const TitleText = styled.span<TypographyProps>`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: ${getTextColorWithDefault};
`
