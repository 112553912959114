import fuzzysort from "fuzzysort"
import { Stack } from "../ui/Stack"
import styled from "@emotion/styled"
import { Magnify } from "../icons/Magnify"
import React from "react"
import { useSearchFilter } from "../../store/SearchFilterStore"
import { useProducts } from "../../store/ProductLoaderStore"
import * as Popover from "@radix-ui/react-popover"
import { TextButton } from "../ui/TextButton"
import { injectMobileStyle } from "../utils/mediaScreenUtils"
import { css } from "@emotion/react"

const SearchInputWrapper = styled.div`
  position: relative;
  height: 32px;

  ${injectMobileStyle(css`
    width: 100%;
  `)}
`

const SearchInput = styled.input`
  all: unset;
  background-color: ${(p) => p.theme.colors.neutral03};
  border-radius: 4px;
  padding-left: 32px;
  height: 100%;

  font-size: 14px;
  font-weight: 400;
  width: 344px;

  ${injectMobileStyle(
    css`
      width: 100%;
      box-sizing: border-box;
    `
  )}

  color: ${(p) => p.theme.colors.text1};

  &:focus {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.ublue08};
  }
`

const SearchIcon = styled.div`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  color: ${(p) => p.theme.colors.neutral08};
  display: flex;
  align-items: center;
`

const PopoverWrapper = styled(Stack)`
  background-color: ${(p) => p.theme.colors.neutral00};
  box-shadow: 0px 16px 32px 0px rgba(28, 30, 45, 0.2);

  padding-top: 8px;
  padding-bottom: 8px;
`

const HighlightMatch = styled.span`
  b {
    text-decoration: underline;
  }
`

const TextMatch = styled(TextButton)`
  display: flex;
  align-items: center;
  height: 32px;
  color: ${(p) => p.theme.colors.text2};

  padding-left: 8px;
  padding-right: 8px;
  border-radius: 0px;
`

const PopoverContent = styled(Popover.Content)`
  z-index: 2147483647;
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
`

const Wrapper = styled(Stack)`
  ${injectMobileStyle(
    css`
      width: 100%;
    `
  )}
`

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Search = (props: SearchProps) => {
  const { isSearching, searchString, onSearch } = useSearchFilter()
  const productRes = useProducts()

  const searchResult = fuzzysort.go(
    searchString,
    productRes.status === "loaded" ? productRes.data : [],
    { limit: 15, keys: [`product.name`] }
  )

  return (
    <Wrapper>
      <Popover.Root open={isSearching}>
        <Popover.Trigger asChild>
          <SearchInputWrapper>
            <SearchIcon>
              <Magnify />
            </SearchIcon>
            <SearchInput
              id="search-input"
              role="search"
              type="text"
              placeholder="Search"
              value={searchString}
              onChange={(e) => {
                onSearch(e.target.value, false)
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  onSearch(searchString, true)
                }
              }}
              {...props}
            />
          </SearchInputWrapper>
        </Popover.Trigger>
        <PopoverContent
          align="start"
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          {searchResult.length > 0 && (
            <PopoverWrapper d="v">
              <Stack d="v">
                {searchResult.map((s) => {
                  return (
                    <TextMatch
                      style={{ justifyContent: "space-between" }}
                      onClick={() => {
                        onSearch(s.obj.product.name, true)
                      }}
                    >
                      <Popover.Close asChild>
                        <HighlightMatch
                          dangerouslySetInnerHTML={{
                            __html: s.at(0)?.highlight() ?? "",
                          }}
                        />
                      </Popover.Close>
                      <span>{s.obj.shortnames.at(0)}</span>
                    </TextMatch>
                  )
                })}
              </Stack>
            </PopoverWrapper>
          )}
        </PopoverContent>
      </Popover.Root>
    </Wrapper>
  )
}
