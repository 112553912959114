import { MainLayout } from "../components/containers/mainLayout"
import { SearchFilter } from "../components/containers/SearchFilter"
import { ListTable } from "../components/containers/ListTable"
import { Stack } from "../components/ui/Stack"
import { useView } from "../store/ViewStore"
import { GridTable } from "../components/containers/GridTable"
import styled from "@emotion/styled"
import { NAVIGATION_BAR_HEIGHT } from "../components/containers/NavigationBar"

const StyledStack = styled(Stack)`
  height: calc(100% - ${NAVIGATION_BAR_HEIGHT}px);
  padding: 16px 32px 0 32px;
  max-width: 1460px;
  width: 100%;
  margin: 0 auto;
`

export const Home = () => {
  const { view } = useView()

  let viewComponent: React.ReactNode = null
  switch (view) {
    case "grid":
      viewComponent = <GridTable />
      break
    case "list":
      viewComponent = <ListTable />
      break
    default:
      return view satisfies never
  }

  return (
    <MainLayout>
      <StyledStack d="v" dist="md" as="main">
        <SearchFilter />
        {viewComponent}
      </StyledStack>
    </MainLayout>
  )
}
