import styled from "@emotion/styled"

export const CTAButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${(p) => p.theme.colors.ublue08};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  border-radius: 4px;

  padding: 6px 2px;

  &:hover {
    color: ${(p) => p.theme.colors.ublue07};
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px ${(p) => p.theme.colors.ublue08};
  }
`
