import styled from "@emotion/styled"
import { Link } from "react-router-dom"

const StyledTableRow = styled(Link)<{ columns: number }>`
  all: unset;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, 1fr);

  padding: 6px 8px;

  border-top: 1px solid transparent;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral03};

  &:hover {
    background-color: ${(p) => p.theme.colors.neutral02};
  }

  &:focus {
    border-bottom: 1px solid ${(p) => p.theme.colors.ublue08};
    border-top: 1px solid ${(p) => p.theme.colors.ublue08};
  }
`

export const TableRow = ({
  to,
  columns,
  children,
}: React.PropsWithChildren<{ to: string; columns: number }>) => {
  return (
    <StyledTableRow to={to} columns={columns}>
      {children}
    </StyledTableRow>
  )
}
