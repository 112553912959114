import styled from "@emotion/styled"
import { Stack } from "../ui/Stack"
import { UbiqImage } from "../ui/UbiqImage"
import { Product } from "../../store/ProductLoaderStore"
import { BodyText, TitleText } from "../ui/Typography"
import React from "react"
import { CTAButton } from "../ui/CTAButton"
import { injectMobileStyle } from "../utils/mediaScreenUtils"
import { css } from "@emotion/react"

const AttributeRow = ({ name, value }: { name: string; value: string }) => (
  <Stack style={{ justifyContent: "space-between", width: "100%" }}>
    <BodyText textColor="black85Percent">{name}</BodyText>
    <BodyText textColor="black45Percent" style={{ textAlign: "right" }}>
      {value}
    </BodyText>
  </Stack>
)

const JSONWrapper = styled.code`
  overflow: auto;
  background-color: ${(p) => p.theme.colors.neutral03};
  padding: 16px;
  white-space: pre;

  max-height: 500px;
`

const ImageWrapper = styled.div`
  max-width: 260px;
  max-height: 260px;

  background-color: ${(p) => p.theme.colors.neutral01};
  padding: 16px;
  aspect-ratio: 1;
`

const Wrapper = styled(Stack)`
  padding-top: 18px;
  margin: 0 auto;

  ${injectMobileStyle(css`
    flex-direction: column;
    align-items: center;
    width: 100%;
  `)}
`

const AttributeWrapper = styled(Stack)`
  width: 444px;

  ${injectMobileStyle(css`
    width: 100%;
  `)}
`

export const DetailsContent = ({ product }: { product: Product }) => {
  const [showJson, setShowJson] = React.useState(false)
  return (
    <Wrapper d="h" dist="xl" role="contentinfo">
      <ImageWrapper>
        <UbiqImage
          alt={`Image of ${product.product.name}`}
          id={product.id}
          size={260}
          defaultId={product.images.default}
        />
      </ImageWrapper>
      <AttributeWrapper d="v" dist="md">
        <Stack d="v" dist="xs">
          <TitleText textColor="black85Percent">
            {product.product.name}
          </TitleText>
          <BodyText>{product.line.name}</BodyText>
        </Stack>
        <Stack d="v" dist="sm" role="definition">
          <AttributeRow name="Product Line" value={product.line.name} />
          <AttributeRow name="ID" value={product.line.id} />
          <AttributeRow name="Name" value={product.product.name} />
          <AttributeRow
            name="Short Name"
            value={product.shortnames.join(", ")}
          />
        </Stack>
        <CTAButton
          onClick={() => setShowJson((s) => !s)}
          style={{ alignSelf: "flex-start" }}
        >
          {showJson ? "Hide JSON" : "See All Details as JSON"}
        </CTAButton>
        {showJson && (
          <JSONWrapper>{JSON.stringify(product, null, 2)}</JSONWrapper>
        )}
      </AttributeWrapper>
    </Wrapper>
  )
}
