import styled from "@emotion/styled"
import { Stack } from "../ui/Stack"
import { BodyText } from "../ui/Typography"
import { BackChevron } from "../icons/BackChevron"
import { Link, To } from "react-router-dom"
import { Product } from "../../store/ProductLoaderStore"

const LinkWrapper = styled(Link)`
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.06),
    0px 8px 24px 0px rgba(0, 0, 0, 0.08);

  padding: 4px;
  border-radius: 4px;

  text-decoration: none;

  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.neutral08};

  &:focus-visible {
    outline: 1px solid ${(p) => p.theme.colors.ublue06};
  }
`

interface FloatingButtonProps
  extends React.HTMLAttributes<HTMLAnchorElement>,
    React.PropsWithChildren {
  to: To
}

const FloatingButton = ({ to, children, ...props }: FloatingButtonProps) => (
  <LinkWrapper to={to} {...props}>
    {children}
  </LinkWrapper>
)

export const DetailsHeader = ({
  products,
  productId,
}: {
  products: Product[]
  productId: string
}) => {
  const productIndex = products.findIndex((p) => p.id === productId)

  const prevProduct = products.at(productIndex - 1)
  const nextProduct = products.at(
    productIndex + 1 < products.length ? productIndex + 1 : 0
  )

  return (
    <Stack
      style={{ padding: "18px 0", justifyContent: "space-between" }}
      role="navigation"
    >
      <FloatingButton to="/">
        <BackChevron />
        <BodyText>Back</BodyText>
      </FloatingButton>
      <Stack dist="xs">
        <FloatingButton
          to={`/details/${prevProduct?.id}`}
          aria-label="Previous item"
        >
          <BackChevron />
        </FloatingButton>
        <FloatingButton
          to={`/details/${nextProduct?.id}`}
          aria-label="Next item"
        >
          <BackChevron style={{ transform: "rotate(180deg)" }} />
        </FloatingButton>
      </Stack>
    </Stack>
  )
}
