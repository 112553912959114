import styled from "@emotion/styled"
import React from "react"

const StyledIconButton = styled.button<{ isActive: boolean }>`
  all: unset;

  border-radius: 4px;

  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${(p) =>
    p.isActive ? p.theme.colors.ublue08 : p.theme.colors.neutral08};

  &:hover {
    background-color: ${(p) => p.theme.colors.neutral02};
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.ublue08};
  }
`

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean
  icon: React.ReactNode
}

export const IconButton = ({
  icon,
  isActive,
  ...otherProps
}: IconButtonProps) => {
  return (
    <StyledIconButton isActive={isActive} {...otherProps}>
      {icon}
    </StyledIconButton>
  )
}
