import styled from "@emotion/styled"
import React from "react"

type Direction = "v" | "h"
type Distance = "void" | "xs" | "sm" | "md" | "lg" | "xl"

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: ${(props) => (props.d === "v" ? "column" : "row")};
  gap: ${(props) => {
    switch (props.dist) {
      case "xs":
        return "4px"
      case "sm":
        return "8px"
      case "md":
        return "16px"
      case "lg":
        return "24px"
      case "xl":
        return "32px"
      case undefined:
      case "void":
        return "0px"
      default:
        return props.dist satisfies never
    }
  }};
`

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  d?: Direction
  dist?: Distance
}
