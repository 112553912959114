import styled from "@emotion/styled"

export const TextButton = styled.button`
  all: unset;

  cursor: pointer;
  color: ${(p) => p.theme.colors.text3};

  height: 32px;
  padding: 0 6px;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    background-color: ${(p) => p.theme.colors.neutral02};
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.ublue08};
  }
`
