import styled from "@emotion/styled"
import React from "react"

const StyledImage = styled.img<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  filter: ${(p) => (p.isLoading ? "blur(10px)" : "blur(0)")};
  opacity: ${(p) => (p.isLoading ? 0.5 : 1)};
  transition-property: opacity, filter;
  transition-duration: 300ms;
`

interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "id"> {
  id: string
  defaultId?: string
  size: number
}

export const UbiqImage = ({
  id,
  defaultId,
  size,
  ...otherProps
}: ImageProps) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const imageIdRef = React.useRef(id)

  const src = defaultId
    ? `https://images.svc.ui.com/?u=https%3A%2F%2Fstatic.ui.com%2Ffingerprint%2Fui%2Fimages%2F${id}%2Fdefault%2F${defaultId}.png&w=${size}&q=75`
    : undefined

  React.useEffect(() => {
    if (imageIdRef.current !== id) {
      setIsLoading(true)
      imageIdRef.current = id
    }
  }, [id])

  return (
    <StyledImage
      isLoading={isLoading && !!defaultId}
      src={src}
      alt={otherProps.alt}
      {...otherProps}
      loading="lazy"
      onLoad={() => {
        setIsLoading(false)
      }}
    />
  )
}
