import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Home } from "./routes/Home"
import { Details } from "./routes/Details"
import { Theme, ThemeProvider } from "@emotion/react"
import { ProductLoaderProvider } from "./store/ProductLoaderStore"
import { SearchFilterProvider } from "./store/SearchFilterStore"
import { ViewProvider } from "./store/ViewStore"

declare module "@emotion/react" {
  export interface Theme {
    colors: {
      neutral10: string
      neutral08: string
      neutral06: string
      neutral03: string
      neutral02: string
      neutral01: string
      neutral00: string
      black45Percent: string
      black65Percent: string
      black85Percent: string
      ublue08: string
      ublue07: string
      ublue06: string
      text3: string
      text2: string
      text1: string
      red06: string
      red03: string
    }
  }
}

const theme: Theme = {
  colors: {
    neutral10: "#50565E",
    neutral08: "#838691",
    neutral06: "#B6B9C4",
    neutral03: "#EDEDF0",
    neutral02: "#F4F5F6",
    neutral01: "#F9FAFA",
    neutral00: "#FFFFFF",
    black45Percent: "#00000073",
    black65Percent: "#000000A6",
    black85Percent: "#000000D9",
    ublue08: "#006FFF",
    ublue07: "#0059CC",
    ublue06: "#006FFF",
    text3: "#808893",
    text2: "#50565E",
    text1: "#212327",
    red06: "#F03A3E",
    red03: "#F9B0B2",
  },
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  { path: "/details/:id", element: <Details /> },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ProductLoaderProvider>
        <SearchFilterProvider>
          <ViewProvider>
            <RouterProvider router={router} />
          </ViewProvider>
        </SearchFilterProvider>
      </ProductLoaderProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
