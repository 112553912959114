import styled from "@emotion/styled"
import { Stack } from "../ui/Stack"
import { Product } from "../../store/ProductLoaderStore"
import { UbiqImage } from "../ui/UbiqImage"
import { BodyText, SubTitle } from "../ui/Typography"
import { Link } from "react-router-dom"
import React from "react"

export const PRODUCT_CARD_WIDTH = 216
export const PRODUCT_CARD_HEIGHT = 172

const Wrapper = styled(Link)`
  all: unset;
  display: flex;
  flex-direction: column;

  cursor: pointer;

  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.neutral03};

  width: ${PRODUCT_CARD_WIDTH}px;
  height: 172px;

  overflow: hidden;

  &:focus-visible {
    border-color: ${(p) => p.theme.colors.ublue06};
  }
`

const TopContainer = styled.div<{ isHover: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: ${(p) =>
    p.isHover ? p.theme.colors.neutral02 : p.theme.colors.neutral01};
`

const BottomContainer = styled(Stack)<{ isHover: boolean }>`
  background-color: ${(p) =>
    p.isHover ? p.theme.colors.neutral01 : p.theme.colors.neutral00};
`

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
`

const ProductLineContainer = styled.div`
  display: flex;
  position: absolute;
  top: 3px;
  right: 3px;

  background-color: ${(p) => p.theme.colors.neutral00};
  border-radius: 4px;
  padding: 2px 4px;
`

const StyledSubTitle = styled(SubTitle)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const StyledBodyText = styled(BodyText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ProductCard = ({ product }: { product: Product }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Wrapper
      to={`/details/${product.id}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <TopContainer isHover={isHover}>
        <ProductLineContainer>
          <SubTitle textColor="ublue06">{product.line.name}</SubTitle>
        </ProductLineContainer>
        <ImageWrapper>
          <UbiqImage
            defaultId={product.images.default}
            id={product.id}
            size={100}
          />
        </ImageWrapper>
      </TopContainer>
      <BottomContainer
        isHover={isHover}
        d="v"
        style={{
          padding: 8,
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <StyledBodyText>{product.product.name}</StyledBodyText>
        <StyledSubTitle>{product.shortnames.join(", ")}</StyledSubTitle>
      </BottomContainer>
    </Wrapper>
  )
}
