import React from "react"

type View = "list" | "grid"

interface State {
  view: View
  setView: React.Dispatch<React.SetStateAction<"grid" | "list">>
}

const initialState: State = {
  view: "list",
  setView: () => {
    throw new Error("TableViewProvider missing")
  },
}

const Context = React.createContext(initialState)
export const useView = () => React.useContext(Context)
export const ViewProvider = ({ children }: React.PropsWithChildren) => {
  const [view, setView] = React.useState(initialState.view)

  return (
    <Context.Provider value={{ view, setView }}>{children}</Context.Provider>
  )
}
