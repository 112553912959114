import * as Popover from "@radix-ui/react-popover"
import { useProducts } from "../../store/ProductLoaderStore"
import { useSearchFilter } from "../../store/SearchFilterStore"
import { Grid } from "../icons/Grid"
import { List } from "../icons/List"
import { IconButton } from "../ui/IconButton"
import { Search } from "./Search"
import { Stack } from "../ui/Stack"
import { TextButton } from "../ui/TextButton"
import { BodyText } from "../ui/Typography"
import { FilterPopover } from "./FilterPopover"
import styled from "@emotion/styled"
import { useView } from "../../store/ViewStore"
import { injectMobileStyle } from "../utils/mediaScreenUtils"
import { css } from "@emotion/react"

const FilterButton = styled(TextButton)`
  &[data-active="true"] {
    color: ${(p) => p.theme.colors.ublue08};
  }
  &[data-state="open"] {
    background-color: ${(p) => p.theme.colors.neutral01};
    color: ${(p) => p.theme.colors.ublue08};
  }
`

const Wrapper = styled(Stack)`
  ${injectMobileStyle(css`
    flex-direction: column;
  `)}
`

export const SearchFilter = () => {
  const productRes = useProducts()
  const { productLineFilter, searchedProducts } = useSearchFilter()
  const { view, setView } = useView()

  if (productRes.status === "failed") {
    return <BodyText> Something went wrong: {productRes.error}</BodyText>
  }

  return (
    <Wrapper style={{ alignItems: "center" }} dist="md">
      <Search />
      <Stack
        dist="md"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <BodyText>{searchedProducts.length} Devices</BodyText>
        <Stack dist="sm" style={{ alignItems: "center" }}>
          <Stack>
            <IconButton
              icon={<List />}
              isActive={view === "list"}
              onClick={() => setView("list")}
            />
            <IconButton
              icon={<Grid />}
              isActive={view === "grid"}
              onClick={() => setView("grid")}
            />
          </Stack>
          {productRes.status === "loaded" && (
            <Popover.Root>
              <Popover.Trigger asChild>
                <FilterButton data-active={productLineFilter.length > 0}>
                  Filter{" "}
                  {productLineFilter.length > 0
                    ? `(${productLineFilter.length})`
                    : null}
                </FilterButton>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  align="end"
                  style={{ zIndex: 2147483647, height: 400 }}
                >
                  <FilterPopover products={productRes.data} />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          )}
        </Stack>
      </Stack>
    </Wrapper>
  )
}
