export const Checkmark = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.93801 9.57502L5.17056 7.80756C4.9753 7.61231 4.65872 7.61231 4.46346 7.80756C4.26824 8.00279 4.2682 8.31929 4.46336 8.51456L5.94798 9.99999L6.00002 10.05L6.2319 10.2819C6.62242 10.6724 7.25559 10.6724 7.64611 10.2819L11.5355 6.39254C11.7307 6.19729 11.7307 5.88074 11.5355 5.6855C11.3403 5.49027 11.0237 5.49025 10.8285 5.68545L6.93801 9.57502Z"
      fill="currentColor"
    />
  </svg>
)
