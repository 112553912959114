import React from "react"

export interface Product {
  id: string
  line: { id: string; name: string }
  product: {
    name: string
  }
  shortnames: string[]
  images: {
    default?: string
  }
}

interface Loading {
  status: "loading"
}
interface Loaded {
  status: "loaded"
  data: Product[]
}
interface Failed {
  status: "failed"
  error: string
}

type State = Loading | Loaded | Failed

const initialState: State = { status: "loading" }

const Context = React.createContext<State>(initialState)
export const useProducts = () => React.useContext(Context)

export const ProductLoaderProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [state, setState] = React.useState<State>(initialState)

  React.useEffect(() => {
    const fetchProducts = async () => {
      const resp = await fetch(
        "https://static.ui.com/fingerprint/ui/public.json"
      )

      const devices = (await resp.json()).devices
     
      setState({
        status: "loaded",
        data: devices.map(
          (device: Partial<Product>): Product => ({
            id: device.id ?? "-",
            images: device.images ?? { default: undefined },
            line: device.line ?? { id: "-", name: "-" },
            product: device.product ?? { name: "-" },
            shortnames: device.shortnames ?? ["-"],
          })
        ),
      })
    }

    fetchProducts()
  }, [])

  return <Context.Provider value={state}>{children}</Context.Provider>
}
