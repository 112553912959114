import React from "react"
import { useSearchFilter } from "../../store/SearchFilterStore"
import {
  PRODUCT_CARD_HEIGHT,
  PRODUCT_CARD_WIDTH,
  ProductCard,
} from "./ProductCard"
import { useVirtualizer } from "@tanstack/react-virtual"

const GRID_GAP = 16

export const GridTable = () => {
  const parentRef = React.useRef<HTMLDivElement>(null)

  const { searchedProducts } = useSearchFilter()

  const rowVirtualizer = useVirtualizer({
    count: searchedProducts.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => PRODUCT_CARD_HEIGHT + GRID_GAP,
    lanes: 6,
    overscan: 5,
  })

  return (
    <div
      ref={parentRef}
      style={{
        flex: 1,
        overflow: "auto",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
          const result = searchedProducts[virtualRow.index]

          return (
            <div
              key={`${virtualRow.index}-${virtualRow.lane}`}
              style={{
                position: "absolute",
                top: 0,
                left: `${virtualRow.lane * (PRODUCT_CARD_WIDTH + GRID_GAP)}px`,
                width: PRODUCT_CARD_WIDTH,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <ProductCard product={result.obj} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
