import React from "react"
import { TableRow } from "../ui/TableRow"
import { BodyText, BoldText } from "../ui/Typography"
import { useVirtualizer } from "@tanstack/react-virtual"
import styled from "@emotion/styled"
import { Stack } from "../ui/Stack"
import { UbiqImage } from "../ui/UbiqImage"
import { useSearchFilter } from "../../store/SearchFilterStore"

const TableHeader = styled.div`
  position: sticky;
  top: 0;
  padding-bottom: 6px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  background-color: ${(p) => p.theme.colors.neutral00};
  z-index: 1;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral03};
`

const ImageWrapper = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
`

export const ListTable = () => {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const { searchedProducts } = useSearchFilter()

  const rowVirtualizer = useVirtualizer({
    count: searchedProducts.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 33,
  })

  return (
    <div
      ref={parentRef}
      style={{
        flex: 1,
        overflow: "auto",
      }}
    >
      <TableHeader>
        <BoldText textColor="black85Percent" style={{ paddingLeft: 44 }}>
          Product line
        </BoldText>
        <BoldText textColor="black85Percent">Name</BoldText>
      </TableHeader>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const result = searchedProducts[virtualRow.index]

          return (
            <div
              key={virtualRow.index}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <TableRow
                key={virtualRow.index}
                columns={2}
                to={`/details/${result.obj.id}`}
              >
                <Stack dist="md" style={{ overflow: "hidden" }}>
                  <ImageWrapper>
                    <UbiqImage
                      id={result.obj.id}
                      defaultId={result.obj.images.default}
                      size={20}
                    />
                  </ImageWrapper>
                  <BodyText
                    textColor="black65Percent"
                    style={{
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {result.obj.line.name}
                  </BodyText>
                </Stack>
                <BodyText
                  textColor="black65Percent"
                  style={{
                    textWrap: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {result.obj.product.name}
                </BodyText>
              </TableRow>
            </div>
          )
        })}
      </div>
    </div>
  )
}
