export const Magnify = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8529 16.1465L14.0059 13.3025C14.9369 12.2115 15.4999 10.7975 15.4999 9.25049C15.4999 5.79849 12.7019 3.00049 9.24988 3.00049C5.79788 3.00049 2.99988 5.79849 2.99988 9.25049C2.99988 12.7025 5.79788 15.5005 9.24988 15.5005C10.7949 15.5005 12.2079 14.9385 13.2989 14.0095L16.1469 16.8535C16.2449 16.9515 16.3719 16.9995 16.4999 16.9995C16.6279 16.9995 16.7559 16.9505 16.8539 16.8525C17.0489 16.6585 17.0489 16.3415 16.8529 16.1465ZM3.99988 9.25049C3.99988 6.35549 6.35488 4.00049 9.24988 4.00049C12.1449 4.00049 14.4999 6.35549 14.4999 9.25049C14.4999 12.1455 12.1449 14.5005 9.24988 14.5005C6.35488 14.5005 3.99988 12.1455 3.99988 9.25049Z"
      fill="#838691"
    />
  </svg>
)
