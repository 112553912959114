import { Stack } from "../ui/Stack"
import { NavigationBar } from "./NavigationBar"

export const MainLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <Stack d="v" style={{ height: "100%" }}>
      <NavigationBar />
      {children}
    </Stack>
  )
}
