import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Checkmark } from "../icons/Checkmark"
import { Label } from "./Typography"
import styled from "@emotion/styled"
import { Stack } from "./Stack"
import React from "react"

const StyledRoot = styled(CheckboxPrimitive.Root)`
  all: unset;
  background-color: ${(p) =>
    p.checked ? p.theme.colors.ublue06 : p.theme.colors.neutral00};
  color: ${(p) => p.theme.colors.neutral00};
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${(p) => (p.checked ? p.theme.colors.ublue06 : p.theme.colors.neutral06)};

  &[data-state="checked"] {
    background-color: ${(p) => p.theme.colors.ublue06};
    border-color: ${(p) => p.theme.colors.ublue06};
  }

  &:hover {
    border-color: ${(p) => p.theme.colors.ublue06};
  }
`

const StyledStack = styled(Stack)`
  border-radius: 4px;
  padding: 1px;
  &:focus-within {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.colors.ublue08};
    outline: none;
  }
`

interface CheckboxProps extends CheckboxPrimitive.CheckboxProps {
  label: string
}

export const Checkbox = ({ label, ...otherProps }: CheckboxProps) => {
  const id = React.useId()

  return (
    <StyledStack style={{ alignItems: "center" }} dist="sm">
      <StyledRoot className="CheckboxRoot" id={id} {...otherProps}>
        <CheckboxPrimitive.Indicator className="CheckboxIndicator" asChild>
          <Checkmark />
        </CheckboxPrimitive.Indicator>
      </StyledRoot>
      <Label htmlFor={id} textColor="black65Percent">
        {label}
      </Label>
    </StyledStack>
  )
}
